<template>
  <div class="seedDeclarationBox">
    <div class="mainBox">
      <div class="main_inside">
        <div class="sdb_title">全国种子管理综合业务平台</div>
        <div class="sdb_midBox">
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_pink">
              <div class="pic1">品种登记</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_yellow">
              <div class="pic2">品种审定</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_blue">
              <div class="pic3">经营备案</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_green">
              <div class="pic4">种子进口</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_yellow">
              <div class="pic5">品种保护</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_green">
              <div class="pic6">种子储备</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_pink">
              <div class="pic7">品种推广</div>
            </div>
          </div>
          <div class="sdb_mb_block">
            <div class="sdb_mb_inblock bg_blue">
              <div class="pic8">种子出口</div>
            </div>
          </div>
        </div>
        <div class="sdb_btnBox">
          <div class="sdb_btn" @click="toOtherWeb">
            <span class="sdb_btn_logo">立刻查看详情</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toOtherWeb() {
        window.open('http://202.127.42.47:8015/Admin.aspx');
    }
  }
};
</script>
<style lang="less">
.seedDeclarationBox {
  .mainBox {
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    background-image: url("~@/assets/images/seed/zisbbjt@2x.png");
    background-size: 100%;
    .main_inside {
      width: 50%;
      height: 100%;
      .sdb_title {
        color: #19316f;
        font-size: 23px;
        line-height: 120px;
        text-align: center;
        letter-spacing: 6px;
      }
      .sdb_midBox {
        width: 460px;
        height: 230px;
        margin: 0 auto;
        .sdb_mb_block {
          width: 25%;
          height: 50%;
          float: left;
          position: relative;
          .sdb_mb_inblock {
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 15px;
            text-align: center;
            line-height: 160px;
          }
        }
      }
      .sdb_btnBox {
        width: 460px;
        margin: 0 auto;
        .sdb_btn {
          width: 160px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          background-color: #265ae1;
          margin: 40px 0;
          cursor: pointer;
          border-radius: 5px;
          .sdb_btn_logo::after {
            content: "\2794";
            color: #fff;
            border-radius: 50%;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.bg_pink {
  background-color: #ffefef;
  color: #fd6666;
}
.bg_yellow {
  background-color: #fff9ec;
  color: #fca14f;
}
.bg_blue {
  background-color: #ecf3ff;
  color: #448afc;
}
.bg_green {
  background-color: #ecfff9;
  color: #42a98a;
}
.pic1 {
  height: 100%;
  background: url("~@/assets/images/seed/pzdj-r@2x.png") no-repeat 50% 30%;
}
.pic2 {
  height: 100%;
  background: url("~@/assets/images/seed/pzsd-y@2x.png") no-repeat 50% 30%;
}
.pic3 {
  height: 100%;
  background: url("~@/assets/images/seed/jyba-b@2x.png") no-repeat 50% 30%;
}
.pic4 {
  height: 100%;
  background: url("~@/assets/images/seed/zijk-icon.png") no-repeat 50% 30%;
}
.pic5 {
  height: 100%;
  background: url("~@/assets/images/seed/pzbh-y@2x.png") no-repeat 50% 30%;
}
.pic6 {
  height: 100%;
  background: url("~@/assets/images/seed/zzcb-g@2x.png") no-repeat 50% 30%;
}
.pic7 {
  height: 100%;
  background: url("~@/assets/images/seed/pztg-r@2x.png") no-repeat 50% 30%;
}
.pic8 {
  height: 100%;
  background: url("~@/assets/images/seed/zjck-icon.png") no-repeat 50% 30%;
}
</style>